import dayjs from 'dayjs';
import { formatDatePickerDate } from '../../utils/formatDatePickerDate';
import { useAuthContext } from '../../contexts/AuthContext';
import { useHistory } from '../../utils/historyUtils';

/**
 * Custom hook for configuring single vehicle data.
 *
 * @param {Object} options - The configuration options.
 * @param {Object} options.currentVehicleData - The current vehicle data.
 * @param {Function} options.setCurrentVehicleData - The function to set the current vehicle data.
 * @param {string} options.company - The company name.
 * @param {string} options.fleet_type - The fleet type.
 * @returns {Object} - The configuration object.
 */
export const useConfig = ({
  currentVehicleData,
  setCurrentVehicleData,
  fleet_type,
  setCurrentContract
}) => {
  const { company } = useAuthContext();
  const { setInitialHistory, createHistory, updateHistory } = useHistory();

  const config = {
    onGetResource: {
      setFields: (data) => {
        setCurrentContract(data.contract);
        let dataToSet = {};
        if (
          data?.initial_traffic_release ||
          data?.principal_driver ||
          data?.secondary_drivers
        ) {
          dataToSet = {
            initial_traffic_release: formatDatePickerDate(
              data.initial_traffic_release
            ),
            principal_driver: data?.principal_driver,
            secondary_drivers: data?.secondary_drivers,
            light_vehicle_type: data?.light_vehicle_type
          };
        }
        dataToSet.fleet_type = data.fleet_type;
        setCurrentVehicleData({
          ...currentVehicleData,
          ...dataToSet
        });
        setInitialHistory(data);
        return {
          ...data,
          initial_traffic_release:
            data.initial_traffic_release && dayjs(data.initial_traffic_release),
          purchase_date_or_lease_start_date:
            data.purchase_date_or_lease_start_date &&
            dayjs(data.purchase_date_or_lease_start_date),
          created_at: data.created_at && dayjs(data.created_at),
          desired_effective_date:
            data.desired_effective_date && dayjs(data.desired_effective_date),
          taxable_power:
            data.light_vehicle_type !== 'MOTORCYCLE'
              ? data?.taxable_power
              : null,
          piston_displacement:
            data.light_vehicle_type === 'MOTORCYCLE'
              ? data?.piston_displacement
              : null
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company,
        programmes_types: 'FLEET',
        fleet_type,
        principal_driver:
          currentVehicleData?.principal_driver === undefined
            ? null
            : currentVehicleData?.principal_driver,
        ...createHistory('program')
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        principal_driver:
          currentVehicleData?.principal_driver === undefined
            ? null
            : currentVehicleData?.principal_driver,
        taxable_power:
          data.light_vehicle_type !== 'MOTORCYCLE' ? data?.taxable_power : null,
        piston_displacement:
          data.light_vehicle_type === 'MOTORCYCLE'
            ? data?.piston_displacement
            : null,
        ...updateHistory('program')
      })
    }
  };
  return { config };
};
