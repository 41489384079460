import dayjs from 'dayjs';
import { formatDatePickerDate } from '../../../../../utils/formatDatePickerDate';
import { useHistory } from '../../../../../utils/historyUtils';

/**
 * Custom hook to generate configuration for handling resource operations.
 * @hook
 *
 * @param {Object} params - The parameters for the configuration.
 * @param {Function} params.setCurrentContract - Function to set the current contract.
 * @param {Function} params.setCurrentDates - Function to set the current dates.
 * @param {string} params.company - The company name.
 * @param {string} params.fleet_type - The type of fleet.
 * @param {Function} params.setCurrentVehicleData - Function to set the current vehicle data.
 * @returns {Object} The configuration object.
 * @returns {Object} config.onGetResource - Configuration for getting a resource.
 * @returns {Function} config.onGetResource.setFields - Function to set fields when getting a resource.
 * @returns {Object} config.onCreateResource - Configuration for creating a resource.
 * @returns {Function} config.onCreateResource.setBody - Function to set the body when creating a resource.
 * @returns {Object} config.onUpdateResource - Configuration for updating a resource.
 * @returns {Function} config.onUpdateResource.setBody - Function to set the body when updating a resource.
 */
export const useConfig = ({
  setCurrentContract,
  setCurrentDates,
  company,
  fleet_type,
  setCurrentVehicleData
}) => {
  const { setInitialHistory, createHistory, updateHistory } = useHistory();
  const config = {
    onGetResource: {
      setFields: (data) => {
        setInitialHistory(data);
        setCurrentContract(data.contract);
        if (data?.start_of_the_mission) {
          setCurrentDates({
            start_of_the_mission: formatDatePickerDate(
              data?.start_of_the_mission
            )
          });
        }
        setCurrentVehicleData({ fleet_type: data?.fleet_type });
        return {
          ...data,
          start_of_the_mission:
            data.start_of_the_mission && dayjs(data.start_of_the_mission),
          planned_end_of_the_mission:
            data.planned_end_of_the_mission &&
            dayjs(data.planned_end_of_the_mission),
          created_at: data.created_at && dayjs(data.created_at),
          desired_effective_date:
            data.desired_effective_date && dayjs(data.desired_effective_date),
          departure_time: data.departure_time && dayjs(data.departure_time)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company,
        programmes_types: 'FLEET',
        fleet_type,
        ...createHistory('program')
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...updateHistory('program')
      })
    }
  };

  return config;
};
