import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { DriverDrawer } from '../components/DriverDrawer';
import useFields from './utils/fieldsOtherVehicles';
import { OtherVehiclesContext } from './OtherVehiclesContext';
import { useConfig } from '../../singleVehiclesConfig';
import { useVerifyAndWrongVehiclesUrl } from '../utils/urlRedirection';

/**
 * `OtherVehiclesCreateUpdate` is a React component for creating or updating documents.
 *
 * Depending on the provided `purpose` prop, this component can be used to either create or edit a document.
 * The component utilizes the `CreateUpdateContainer` for rendering the input fields and managing form actions.
 *
 * Note: This component does not support managing files.
 *
 * @component
 * @param {object} props The props for the component.
 * @param {string} props.purpose Indicates the purpose of the form. It should be either 'create' or 'edit'.
 * @returns {React.ElementType} A form for creating or updating a document.
 */
export const OtherVehiclesCreateUpdate = ({ purpose }) => {
  const { setCurrentEntityContext, setCurrentContract } =
    useContext(OtherVehiclesContext);
  const location = useLocation();
  const [form] = Form.useForm();
  const resource = 'vehicles';
  const fleet_type = 'OTHER_VEHICLE';
  const [isOpen, setIsOpen] = useState(false);
  const [currentVehicleData, setCurrentVehicleData] = useState({
    initial_traffic_release: null,
    principal_driver: null,
    secondary_drivers: []
  });
  const { verifyAndCorrectWorngVehiclesUrl } = useVerifyAndWrongVehiclesUrl();
  const { fieldsOtherVehicles, isFieldsLoading } = useFields({
    onAddDriver: () => setIsOpen(true),
    currentVehicleData,
    setCurrentVehicleData,
    form
  });
  const { companyName, user } = useAuthContext();
  const { t } = useTranslation();

  useEffect(() => {
    verifyAndCorrectWorngVehiclesUrl(currentVehicleData);
  }, [currentVehicleData, location]);

  const draggerFilesKeysOverRide = [
    'vehicle_registration_document',
    'order_form',
    'purchase_invoice',
    'other'
  ];

  const { config } = useConfig({
    currentVehicleData,
    setCurrentVehicleData,
    fleet_type,
    setCurrentContract
  });

  return (
    <>
      <CreateUpdateContainer
        setCurrentEntityContext={setCurrentEntityContext}
        parentForm={form}
        fields={fieldsOtherVehicles}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="vehicles"
        resource={resource}
        config={config}
        extraTitle={
          <Row justify="center" className="form-company-name">{`${t(
            'folder'
          )} ${companyName}`}</Row>
        }
        populate="?populate=documents.file"
        draggerFilesKeysOverRide={draggerFilesKeysOverRide}
      />
      <DriverDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

OtherVehiclesCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
