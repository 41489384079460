import { useContext } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useAuthContext } from '../../../contexts/AuthContext';

import useFields from './utils/fieldsProperty';
import { PropertyContext } from './PropertyContext';
import { useHistory } from '../../../utils/historyUtils';

/**
 * Component for creating or updating properties.
 * @param {Object} props - The component properties.
 * @returns {JSX.Element} The rendered component.
 */

export const PropertyCreateUpdate = ({ purpose }) => {
  const { setCurrentEntityContext, setCurrentContract } =
    useContext(PropertyContext);
  const [form] = Form.useForm();
  const { setInitialHistory, createHistory, updateHistory } = useHistory();
  const resource = 'properties';

  const { fieldsProperty, isFieldsLoading } = useFields(form);
  const { company, companyName, user } = useAuthContext();

  const { t } = useTranslation();
  const config = {
    onGetResource: {
      setFields: (data) => {
        setInitialHistory(data);
        setCurrentContract(data.contract);
        return {
          ...data,
          desired_effective_date:
            data.desired_effective_date && dayjs(data.desired_effective_date),
          date_added: data.date_added && dayjs(data.date_added)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        programmes_types: 'PROPERTY',
        company,
        ...createHistory('program')
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...updateHistory('program')
      })
    }
  };
  return (
    <CreateUpdateContainer
      setCurrentEntityContext={setCurrentEntityContext}
      parentForm={form}
      fields={fieldsProperty}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="properties"
      resource={resource}
      config={config}
      extraTitle={
        <Row justify="center" className="form-company-name">{`${t(
          'folder'
        )} ${companyName}`}</Row>
      }
      populate="?populate=documents.file"
    />
  );
};

PropertyCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
