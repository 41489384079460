import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Custom hook that verifies and corrects the URL path for vehicle types.
 * @hook
 *
 * @returns {Object} An object containing the `verifyAndCorrectWorngVehiclesUrl` function.
 *
 * @function useVerifyAndWrongVehiclesUrl
 * @description This hook uses the current location and navigation functions to ensure the URL path matches the correct vehicle type segment.
 */
export const useVerifyAndWrongVehiclesUrl = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const vehicleTypeMapping = {
    HEAVY_VEHICLE: 'heavy-vehicles',
    LIGHT_VEHICLE: 'light-vehicles',
    OTHER_VEHICLE: 'other-vehicles',
    AUTOMISSION: 'automissions'
  };

  /**
   * Verifies and corrects the URL segment for vehicle types based on the current vehicle data.
   * If the current URL does not match the expected segment for the vehicle type, it redirects to the correct URL.
   * @function
   *
   * @param {Object} currentVehicleData - The data of the current vehicle.
   * @param {string} currentVehicleData.fleet_type - The type of the vehicle fleet.
   * @returns {boolean} - Returns false if no redirection is needed.
   */
  const verifyAndCorrectWorngVehiclesUrl = (currentVehicleData) => {
    if (currentVehicleData?.fleet_type) {
      const correctVehicleSegment =
        vehicleTypeMapping[currentVehicleData.fleet_type];

      if (!location.pathname.includes(correctVehicleSegment)) {
        const newPath = location.pathname.replace(
          /(heavy-vehicles|light-vehicles|other-vehicles|automissions)/,
          correctVehicleSegment
        );
        navigate(newPath);
      }
    }
    return false;
  };

  return { verifyAndCorrectWorngVehiclesUrl };
};
