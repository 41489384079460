import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useAuthContext } from '../../../../contexts/AuthContext';
import useFields from './utils/fieldsAutomissions';
import { AutomissionsContext } from './AutomissionsContext';
import { useVerifyAndWrongVehiclesUrl } from '../utils/urlRedirection';
import { useConfig } from './utils/config';

/**
 * `AutomissionsCreateUpdate` is a React component for creating or updating documents.
 *
 * Depending on the provided `purpose` prop, this component can be used to either create or edit a document.
 * The component utilizes the `CreateUpdateContainer` for rendering the input fields and managing form actions.
 *
 * @component
 * @param {object} props The props for the component.
 * @param {string} props.purpose Indicates the purpose of the form. It should be either 'create' or 'edit'.
 * @returns {React.ElementType} A form for creating or updating a document.
 */

export const AutomissionsCreateUpdate = ({ purpose }) => {
  const [form] = Form.useForm();
  const { setCurrentEntityContext, setCurrentContract } =
    useContext(AutomissionsContext);
  const { company, companyName, user } = useAuthContext();
  const location = useLocation();
  const resource = 'vehicles';
  const fleet_type = 'AUTOMISSION';
  const mandatoryDocuments = ['collaborator_driving_licence'];
  const [currentVehicleData, setCurrentVehicleData] = useState({});
  const draggerFilesKeysOverRide = [
    'collaborator_vehicle_registration_document',
    'collaborator_driving_licence'
  ];
  const draggerFilesKeysMandatory = ['collaborator_driving_licence'];
  const [currentDates, setCurrentDates] = useState({
    start_of_the_mission: null
  });
  const { fieldsAutomissions, isFieldsLoading } = useFields({
    currentDates,
    setCurrentDates,
    form
  });
  const { verifyAndCorrectWorngVehiclesUrl } = useVerifyAndWrongVehiclesUrl();

  useEffect(() => {
    verifyAndCorrectWorngVehiclesUrl(currentVehicleData);
  }, [currentVehicleData, location]);

  const { t } = useTranslation();
  const config = useConfig({
    setCurrentContract,
    setCurrentDates,
    company,
    fleet_type,
    setCurrentVehicleData
  });
  return (
    <CreateUpdateContainer
      setCurrentEntityContext={setCurrentEntityContext}
      parentForm={form}
      fields={fieldsAutomissions}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      mandatoryDocuments={mandatoryDocuments}
      draggerFilesKeysOverRide={draggerFilesKeysOverRide}
      draggerFilesKeysMandatory={draggerFilesKeysMandatory}
      title={t('vehicles.form.title.automissions')}
      baseUrl="vehicles"
      resource={resource}
      config={config}
      extraTitle={
        <Row justify="center" className="form-company-name">{`${t(
          'folder'
        )} ${companyName}`}</Row>
      }
      populate="?populate=documents.file"
    />
  );
};

AutomissionsCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
